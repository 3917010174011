import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
    Button,
    Table,
    Col,
    Row,
    ButtonGroup,
    CardHeader,
    Card,
    CardFooter,
    Alert as RSAlert
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import {
    Header,
    Footer,
    TextFilter,
    SelectFilter,
    Loading,
    SortHeader,
    Pager,
    DateFilter,
    InventoryStatusBadge,
    ResultHeader,
    FilterSearch,
} from "./";
import { constants, api } from "../utils";
import Alert from "react-s-alert-v3";
import {date_helpers, filter_helpers, ui_helpers} from '../helpers';
import {UserContext} from "../utils";
import {isNumber} from "lodash/lang";

const S_IDS = constants.INVENTORY_STATUS_ID;
const NO_OVERRIDE_S_ID = constants.INVENTORY_STATUS_NOT_TO_OVERRIDE;
const FilterOptions = {
    ALL_OPEN: 0,
    IN_REVIEW: 2,
    ON_HOLD: 3,
    IN_STOCK: 4,
    PENDING: 5,
    WEB_SALE: 6,
    SOLD: 7,
    IN_PRODUCTION: 8,
    SAVED_FILTER: 9
};

const InventorySaleRow = ({i, currentUser, onViewBillOfSale, onViewSalesReceipt}) => {
  const history = useHistory();
//const isInventoryOwner = ui_helpers.isInventoryOwner(currentUser, i.manufacturerId, i.companyId);
//const isCurrentManufacturerUser = ui_helpers.isManufacturerUser(currentUser, i.manufacturerBrandId);
  const navPath = `${constants.PATH_NAMES.INVENTORY_VIEW}/${i.unitInventoryId}`;
  const openInventoryItem = () => {
    history.push(navPath);
  };
  const daysAgo = date_helpers.daysAgoFromNow(date_helpers.formatDateToShortDate(i.inventoryOn));
  return (
    <tr className='selectable-row'>
      <td onClick={openInventoryItem}>
        <Col>
          <div>
            {date_helpers.formatDateToShortDate(i.inventoryOn)}
          </div>
          <small>
            <div>
              {daysAgo < 0 ? `in ${daysAgo * -1} days` : null}
              {daysAgo > 0 ? `${daysAgo} day${(daysAgo === 1 ? '' : 's')} ago` : null}
            </div>
          </small>
        </Col>
      </td>
      <td onClick={openInventoryItem}>{`${i.resolvedWidthFeet}' W x ${i.resolvedLengthFeet}' L`}</td>
      <td onClick={openInventoryItem}>{i.unitManufacturerSubProductText}</td>
      <td onClick={openInventoryItem}>{`${i.unitManufacturerBaseColorText}/${i.unitManufacturerTrimColorText}/${i.unitManufacturerRoofColorText}`}</td>
      <td onClick={openInventoryItem}>{i.unitIsNew ? 'NEW' : (<span className="text-danger" style={{fontWeight: 'bold'}}>USED</span>)}</td>
      <td onClick={openInventoryItem}>{i.serialNumber}</td>
      <td onClick={openInventoryItem}>
        {i.paidOn
          ? (<span>{date_helpers.formatDateToShortDate(i.paidOn)} by {i.checkNo || "-"}</span>)
          : (<span className="fst-italic">Unpaid</span>)
        }
      </td>
      <td onClick={openInventoryItem}>{i.newOwnerCompanyName}</td>
      <td onClick={openInventoryItem}>
        <div className="mb-1">
          <InventoryStatusBadge
            inventoryStatus={_.some(NO_OVERRIDE_S_ID, s => s === i.typeOfInventoryStatus) ? i.typeOfInventoryStatus : i.resolvedTypeOfInventoryStatus}
          />
        </div>
      </td>
      <td onClick={openInventoryItem}>{i.invoiceNumber}</td>
      <td onClick={openInventoryItem}>{ui_helpers.formatCurrency(i.purchaseAmount)}</td>
      <td style={{overflow: 'visible', padding: 0}}>
        <a href={navPath} target="_blank" title="Open in new tab" className="open-ext" rel="noreferrer">
          <FontAwesomeIcon icon="external-link-alt"/>
        </a>
        <Row className="mx-0">
          <Col className='d-flex mt-4' onClick={() => onViewBillOfSale(i.billOfSaleAttachmentId)}>
            <span className="btn-link">View Bill of Sale</span>
          </Col>
          <Col className='d-flex mt-4' onClick={() => onViewSalesReceipt(i.saleReceiptId)}>
            <span className="btn-link">View Receipt</span>
          </Col>
        </Row>
      </td>
  </tr>);
}

export default function InventorySales(props) {
const [filters, setFilters] = useState([]);
const [clear, setClear] = useState(false);
const [canClear, setCanClear] = useState(false);
const [pageNumber, setPageNumber] = useState(1);
const [loadingPayload, setLoadingPayload] = useState(null);
const [loadedPayload, setLoadedPayload] = useState(null);
const [paginatedList, setPaginatedList] = useState({});
const [totalPages, setTotalPages] = useState(0);
const [sortDirection, setSortDirection] = useState('desc');
const [sortColumn, setSortColumn] = useState('Age');
const [companies, setCompanies] = useState([]);
const [soldToCompanies, setSoldToCompanies] = useState([]);
// const [totalRetailPrice, setTotalRetailPrice] = useState(0);
// const [manufacturers, setManufacturers] = useState([]);
// const [dealers, setDealers] = useState([]);
// const [users, setUsers] = useState([]);
const [filtersLoaded, setFiltersLoaded] = useState(false);
const [message, setMessage] = useState(null);
const {currentUser} = useContext(UserContext);
const isSysAdmin = ui_helpers.isSystemAdministrator(currentUser);
const isCompanyAdmin = ui_helpers.isAnyCompanyAdministrator(currentUser);
const [totalBadgeClass, setTotalBadgeClass] = useState('bg-dark');
const [errorMessage, setErrorMessage] = useState(null);
const [lastSearch, setLastSearch] = useState(null);
const [pristineLastSearch, setPristineLastSearch] = useState(null);

document.title = `Inventory Sales - ${constants.appName}`;

  useEffect(() => {
    if (filtersLoaded) return;
    api
      .fetch('inventory/GetInventorySalesFilters')
      .then(r => {
        const { companySelect, soldToCompanySelect, userSavedSearch, initialData } = r.data.data;
        companySelect.unshift({ label: '[Any Company]', value: 0 });
        // manufacturerSelect.unshift({ label: '[Any Manufacturer]', value: 0 });
        // dealerSelect.unshift({ label: '[Any Store]', value: 0 });
        // inUsers.unshift({ label: '[Any User]', value: 0 });
        // setDealers(dealerSelect);
        setCompanies(companySelect);
        setSoldToCompanies(soldToCompanySelect);
        // setManufacturers(manufacturerSelect);
        // setUsers(inUsers);
        // setFilterCounts(quickFilterCounts);
        if (userSavedSearch) {
          const savedSearch = JSON.parse(userSavedSearch.serializedSearch);
          const sortDetails = savedSearch.resolvedSort;
          setPageNumber(sortDetails.page);
          setSortColumn(sortDetails.sortColumn);
          const sortDir = sortDetails.sortAscending ? "asc" : "desc";
          setSortDirection(sortDir);
          setLastSearch(userSavedSearch.serializedSearch);
          setPristineLastSearch(userSavedSearch.serializedSearch);
          setLoadedPayload(
            resolvePayload(sortDetails.page, savedSearch.filters, sortDetails.sortColumn, sortDir));
          // setNewOrUsedSelection(
          //   ui_helpers.resolveNewOrUsedSelectionFromFilters(savedSearch.filters));
          setFilters(savedSearch.filters);
          setPaginatedList(initialData);
          // setTotalRetailPrice(initialData.totalPrice);
          // setSelectedFilterOption(FilterOptions.SAVED_FILTER);
        } else {
          setFilters([{filterName: 'OpenOnly', value: true}]);
          // setSelectedFilterOption(FilterOptions.ALL_OPEN);          
        }
        setTotalBadgeClass('bg-dark');
      })
      .catch((error) => {
        console.error(error);
        setMessage({ flavor: 'danger', message: 'Error when trying to get filter data: ', error });
      })
      .finally(() => setFiltersLoaded(true));
  }, [])

  useEffect(getTotalPageCount, [paginatedList]);

  function resolvePayload(pPageNumber = null, pFilters = null, sortCol = null, sortDir = null) {
    let payload = {
      ...resolveSort(sortCol, sortDir),
      page: pPageNumber || pageNumber
    };
    const filterList = pFilters ? pFilters : filters;
    _.each(filterList, filter => payload[filter.filterName] = filter.value);
    return payload;
  }

  function refreshData(forceReload = false) {
    const payload = resolvePayload();
    if (_.isEqual(payload, loadedPayload) && !forceReload) {
      return;
    }
    setLoadingPayload(payload);
    api.post('Inventory/FindInventorySales', payload).then((response) => {
      if (response.data.success) {
        setPaginatedList(response.data.message);
        // setTotalRetailPrice(response.data.message.totalPrice);
      }
    }).catch(error => {
      setMessage({flavor: 'danger', message: 'Error when trying to refresh data: ', error});
      console.error(error);
    }).finally(() => {
      if (filtersLoaded) {
        setLastSearch(
          filter_helpers.storeSaveSearch(filters, resolveSort(), pageNumber, null));
      }
      setLoadedPayload(payload);
      setLoadingPayload(null);
    });
  }

  useEffect(() => {
    if (!filtersLoaded) return;
    refreshData();
  }, [filtersLoaded, pageNumber, sortColumn, sortDirection, filters])

  useEffect(() => {
    if (!filtersLoaded || !lastSearch || _.isEqual(pristineLastSearch, lastSearch)) return;
    filter_helpers.saveSearch(
      constants.SEARCH_AREAS.INVENTORY_SALES, 
      lastSearch
    );
    setPristineLastSearch(lastSearch);
  }, [pristineLastSearch, lastSearch])

  function getTotalPageCount() {
    if (paginatedList && isNumber(paginatedList.totalCount) && isNumber(paginatedList.pageSize)) {
      setTotalPages(Math.ceil(paginatedList.totalCount / paginatedList.pageSize));
    }
  }

  function onViewSalesReceipt(saleReceiptId) {
    const pdfWindow = window.open();
    api.downloadAndOpenFile('inventory/ViewSaleReceipt', {id: saleReceiptId}, pdfWindow, (e) => {
      pdfWindow.close();
      console.error(e);
      Alert.error("There was an error downloading the document");
    });
  }

  function onViewBillOfSale(billOfSaleAttachmentId) {
    const pdfWindow = window.open();
    api.downloadAndOpenFile('inventory/ViewBillOfSale', {id: billOfSaleAttachmentId}, pdfWindow, (e) => {
      pdfWindow.close();
      console.error(e);
      Alert.error("There was an error downloading the document");
    });
  }

  function resolveSort(pSortColumn, pSortDirection) {
    return {
      sortColumn: (pSortColumn || sortColumn),
      sortAscending: ((pSortDirection || sortDirection) === 'asc')
    };
  }

  function findCSV() {
    if (loadingPayload) return;
    const payload = resolvePayload();
    setLoadingPayload(payload);
    const pdfWindow = window.open();
    api.downloadAndOpenFile('Inventory/FindSalesCSV', payload, pdfWindow, (e) => {
        pdfWindow.close();
        setMessage({flavor: 'danger', message: 'Error when trying to export CSV data: ' + e});
      }, constants.MIME_XLSX, () => setLoadingPayload(null));
  }

  function onFilterChange(changedFilter) {
    let filterChanges = filter_helpers.getFilters(filters, changedFilter);
    setPageNumber(1);
    setCanClear(filterChanges.length > 0);
    setFilters(filterChanges);
  }

  function doSort(field, direction){
    setSortColumn(field);
    setSortDirection(direction);
  }

  const clearFilters = () => {
    setFilters([]);
    setClear(!clear);
  };

  return (<>    
    <Header toolbar inventory />
    {message && message.message
      ? (<RSAlert color={message.flavor} toggle={() => setMessage(null)}>{message.message}</RSAlert>) 
      : null
    }
    <FilterSearch heading="Inventory Sales" filters={filters} onClear={clearFilters}>
      <Row>
        <Col lg='2' sm='3'>
          <SelectFilter
            filterName='CompanyIdList'
            displayName='Company'
            value={filter_helpers.get_value('CompanyIdList', filters)}
            clear={clear}
            options={companies}
            isSingleSelect={false}
            isDisabled={!companies}
            onChangeCallback={onFilterChange}
            placeholder={'[Any Company]'}
          />
        </Col>
        <Col lg='2' sm='3'>
          <SelectFilter
            filterName='SoldToCompanyId'
            displayName='Sold To Company'
            value={filter_helpers.get_value('SoldToCompanyId', filters)}
            clear={clear}
            options={soldToCompanies}
            isSingleSelect
            isDisabled={!soldToCompanies}
            onChangeCallback={onFilterChange}
            placeholder={'[Any Company]'}
          />
        </Col>
        <Col lg='2' sm='3'>
          <TextFilter
            filterName='ExternalCompany'
            displayName='Sold To External Company'
            descriptionPrefix="starts with"
            onChangeCallback={onFilterChange}
            value={filter_helpers.get_value('ExternalCompany', filters)}
            debounceTimeout={500}
          />
        </Col>
        <Col lg='2' sm='3'>
          <TextFilter
            filterName='SerialNumber'
            displayName='Serial Number'
            onChangeCallback={onFilterChange}
            value={filter_helpers.get_value('SerialNumber', filters)}
            debounceTimeout={500}
          />
        </Col>
        <Col lg='2' sm='3'>
          <TextFilter
            filterName='InvoiceNumber'
            displayName='Invoice #'
            descriptionPrefix="is"
            onChangeCallback={onFilterChange}
            value={filter_helpers.get_value('InvoiceNumber', filters)}
            debounceTimeout={500}
          />
        </Col>
        <Col lg='2' sm='3'>
          <DateFilter
            filterName='OccurredAfter'
            displayName='After'
            value={filter_helpers.get_value('OccurredAfter', filters)}
            onChangeCallback={onFilterChange}
          />
        </Col>
        <Col lg='2' sm='3'>
          <DateFilter
            filterName='OccurredBefore'
            displayName='Before'
            value={filter_helpers.get_value('OccurredBefore', filters)}
            onChangeCallback={onFilterChange}
          />
        </Col>
      </Row>
    </FilterSearch>
      <Pager
        pageNumber={pageNumber}
        pageSize={paginatedList ? paginatedList.pageSize : 0}
        totalResults={paginatedList ? paginatedList.totalCount : 0}
        onSetPageCallback={setPageNumber}
      />
      <Card>
        <CardHeader>
          <Row className='d-flex justify-content-between'>
            <Col>
              <ResultHeader 
                heading='Inventory Sales' 
                totalResults={paginatedList ? paginatedList.totalCount : 0}
                badgeClass={totalBadgeClass}
              />
            </Col>
            <Col className='d-flex justify-content-end align-items-start'>
              <ButtonGroup>
                <Button onClick={findCSV} color={'light'} className='border-dark'><FontAwesomeIcon size="2x" icon={'file-csv'}/></Button>
              </ButtonGroup>
            </Col>
          </Row>
        </CardHeader>
        {errorMessage
          ? (<RSAlert color={errorMessage.flavor} toggle={() => setErrorMessage(null)}>{errorMessage.message}</RSAlert>)
          : null
        }
        <Table bordered striped hover>
          <thead>
          <tr key={'inventory-header-row'}>
            <SortHeader
              displayName='Age'
              field='Age'
              sortDir={sortDirection}
              sorted={sortColumn === 'Age'}
              sortCallback={doSort}
            />
            <SortHeader
              displayName='Dimensions'
              field='WidthLength'
              sortDir={sortDirection}
              sorted={sortColumn === 'WidthLength'}
              sortCallback={doSort}
            />
            <SortHeader
              displayName='Style'
              field='Style'
              sortDir={sortDirection}
              sorted={sortColumn === 'Style'}
              sortCallback={doSort}
              isAlphabetic
            />
            <SortHeader
              displayName='Description'
              field='BaseColor'
              sortDir={sortDirection}
              sorted={sortColumn === 'BaseColor'}
              sortCallback={doSort}
              isAlphabetic
            />
            <SortHeader
              displayName='Used/New'
              field='UnitIsNew'
              sortDir={sortDirection}
              sorted={sortColumn === 'UnitIsNew'}
              sortCallback={doSort}
              isAlphabetic
            />
            <SortHeader
              displayName='Serial #'
              field='SerialNo'
              sortDir={sortDirection}
              sorted={sortColumn === 'SerialNo'}
              sortCallback={doSort}
              isAlphabetic
            />
            <SortHeader
              displayName='Payment'
              field='PaidOn'
              sortDir={sortDirection}
              sorted={sortColumn === 'PaidOn'}
              sortCallback={doSort}
              isAlphabetic
            />
            <SortHeader
              displayName='Sold To Company'
              field='Company'
              sortDir={sortDirection}
              sorted={sortColumn === 'Company'}
              sortCallback={doSort}
              isAlphabetic
            />
            <SortHeader
              displayName='Status'
              field='Status'
              sortDir={sortDirection}
              sorted={sortColumn === 'Status'}
              sortCallback={doSort}
              isAlphabetic
            />
            <SortHeader
              displayName='Inv #'
              field='InvoiceNo'
              sortDir={sortDirection}
              sorted={sortColumn === 'InvoiceNo'}
              sortCallback={doSort}
            />
            <SortHeader
              displayName='Purchase $'
              field='PurchasePrice'
              sortDir={sortDirection}
              sorted={sortColumn === 'PurchasePrice'}
              sortCallback={doSort}
            />
          </tr>
          </thead>
          <tbody>
          {loadingPayload || !filtersLoaded
              ? (<tr key='loading-row'><td colSpan='13'><Loading /></td></tr>)
              : paginatedList && paginatedList.items && paginatedList.items.length
                  ? _.map(paginatedList.items, (inv, idx) => 
                    (<InventorySaleRow 
                      key={`inv-s-row-${inv.unitInventoryId}-${idx}`}
                      i={inv}
                      currentUser={currentUser}
                      onViewBillOfSale={onViewBillOfSale}
                      onViewSalesReceipt={onViewSalesReceipt}
                    />))
                  : (<tr key='no-data-row'><td colSpan='12' className='text-center'>No Inventory sale items to show.</td></tr>)
          }
          </tbody>
        </Table>
        <CardFooter>
          <Pager 
            pageNumber={pageNumber} 
            pageSize={paginatedList ? paginatedList.pageSize : 0} 
            totalResults={paginatedList ? paginatedList.totalCount : 0} 
            onSetPageCallback={setPageNumber} />
        </CardFooter>
      </Card>
      <Footer />
    </>
  );
}
