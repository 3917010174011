import React, { useEffect, useState, useContext, Fragment } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { Footer, Header, Loading } from "./";
import _ from "lodash";
import { Link } from "react-router-dom";
import { api, constants, UserContext } from "../utils";
import { ui_helpers } from "../helpers";
const PATHNAMES = constants.PATH_NAMES;

const DocBrief = ({ title, dsc, onClick, active }) => {
  return (
    <div
      className={active ? "document-brief active" : "document-brief"}
      onClick={onClick}
    >
      <strong>{title}</strong>
      <br />
      <em>{dsc}</em>
    </div>
  );
};

const DocIndex = () => {
  document.title = `Tutorials - ${constants.appName}`;

  const [isLoading, setIsLoading] = useState(true);
  const [docList, setDocList] = useState([]);
  const [activeDoc, setActiveDoc] = useState();
  const { currentUser } = useContext(UserContext);

  useEffect(() => {
    setIsLoading(true);
    api
      .fetch("Resources/DocList")
      .then((res) => {
        setDocList(res.data);
      })
      .catch((e) => console.error(e))
      .finally(() => setIsLoading(false));
  }, []);

  function buildContentUrl() {
    return `/Content/docs/${activeDoc.key.replaceAll('-', '')}${activeDoc.extension}`;
  }

  function renderPDFContent() {
    const url = buildContentUrl();
    return (
      <div key={url}>
        <object
          width="100%"
          height="650"
          type="application/pdf"
          data={`${url}?#zoom=85&scrollbar=0&toolbar=0&navpanes=0`}
          id="pdf_content"
        >
          <p>The PDF cannot be displayed. Consider upgrading your web browser.</p>
        </object>
      </div>
    );
  }

  function renderVideoContent() {
    const url = buildContentUrl();
    return (
      <Fragment key={url}>
        <video className="w-100" style={{ height: "650" }} controls="controls">
          <source src={url} type="video/mp4" />
        </video>
      </Fragment>
    );
  }

  return (
    <div>
      <Header resources noComments toolbar={ui_helpers.isSystemAdministrator(currentUser)}>
        <Button color="light" tag={Link} to={PATHNAMES.RESOURCES_EDITDOCLIST}>
          Edit Documents
        </Button>
      </Header>
      {isLoading ? (
        <Loading />
      ) : (
        <Container fluid className="py-3">
          <Row>
            <Col xs={8}>
              {!activeDoc ? (
                <div className="w-100 h-100 d-flex justify-content-center align-items-center text-center">
                  <h1>Select an item on the right to view</h1>
                </div>
              ) : (
                <>
                  <h2>{activeDoc.name}</h2>
                  { activeDoc.mimeType === constants.MIME_PDF ? renderPDFContent() : null }
                  { activeDoc.mimeType === constants.MIME_MP4 ? renderVideoContent() : null }
                  { activeDoc.mimeType === constants.MIME_OCTET
                    && activeDoc.extension
                    && activeDoc.extension.toUpperCase() === ".PDF"
                      ? renderPDFContent()
                      : activeDoc.mimeType === constants.MIME_OCTET
                        && activeDoc.extension === ".mp4"
                        ? renderVideoContent()
                        : null
                  }
                </>
              )}
            </Col>
            <Col xs={4}>
              {docList &&
                _.map(docList, (d, i) => (
                  <DocBrief
                    title={d.name}
                    dsc={d.dsc}
                    key={d.id}
                    onClick={() => setActiveDoc(docList[i])}
                    active={activeDoc === docList[i]}
                  />
                ))}
            </Col>
          </Row>
        </Container>
      )}
      <Footer />
    </div>
  );
};

export default DocIndex;
