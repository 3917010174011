import React, { useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { PointerCoordinatesProvider } from "../state/providers";
import { DrawingCanvas, PrimarySidebar, SecondarySidebar, Toolbar } from "./";

function Main({ saveCallback, onExit, hasExistingFloorPlan, modelName, editingContext }) {
  const [showIntro, setShowIntro] = useState(
    localStorage.getItem("showIntro") === "false" ? false : true
  );

  return (
    <div id="main">
      <PointerCoordinatesProvider>
        <PrimarySidebar editingContext={editingContext} />
        <SecondarySidebar />
        <Toolbar modelName={modelName} saveCallback={saveCallback} onExit={onExit} hasExistingFloorPlan={hasExistingFloorPlan} />
        <DrawingCanvas />
      </PointerCoordinatesProvider>
      <Modal size="lg" isOpen={showIntro}>
        <ModalHeader>Welcome to the Carefree Floorplan Designer!</ModalHeader>
        <ModalBody>
          <h4 className="lead">Quick Start Guide</h4>
          <p>
            The floorplan designer allows for quick and easy creation of 2d
            drawings to help the manufacturer customize a building to the
            customer's exact specifications.
          </p>

          <p>
            The exterior shape of the building will be pre-loaded into the
            program, along with a background grid and horizontal and vertical
            rulers to aid in placement of options. To hide these rulers or the
            background grid, use the checkboxes in the toolbar at the top of the
            screen. You can also adjust the <strong>ruler offset</strong> to
            move the rulers further away or closer to the building if desired.
          </p>

          <p>
            The list of available add-ons is in the sidebar on the left of the
            screen. To place an add-on on your drawing click the{" "}
            <strong>plus icon</strong> next to the item you want to add.
            Clicking the minus icon will remove the most recently added item of
            that type.
          </p>

          <p>
            Once an add-on is placed on the drawing, you can click and drag to
            move it into the desired position. Many add-ons can also be rotated
            and/or resized by clicking and dragging the control hanldles. In
            addition to clicking and dragging, you can achieve more
            fined-grained control over the positioning over the selected add-on,
            as well as access additional settings, in the{" "}
            <strong>Add-On Settings</strong> panel on the right sidebar.
          </p>

          <p>
            Once your drawing is ready to go, click the{" "}
            <strong>save button</strong> near the top left corner of the screen.
          </p>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <FormGroup check>
            <Label>Don't show this message again.</Label>
            <Input
              type="checkbox"
              onChange={(e) =>
                localStorage.setItem("showIntro", !e.target.checked)
              }
            />
          </FormGroup>
          <Button color="primary" onClick={() => setShowIntro(false)}>
            Get Started
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Main;
