import { api, constants } from "../utils";
import _ from "lodash";
import Alert from "react-s-alert-v3";
/**
 * FEET values need to be calculated to inches
 * DOORS  only have width
 * **/

const mapAddonOptionsFromFP = (options, context) => {
  let mappedOptions = _.map(options, (o) => {
    return {
      addOnId: o.addonOptionId,
      bundleId: o.bundleId,
      addOnName: o.name,
      addOnTypeId: o.type,
      price: o.price,
      unitOfMeasureId: o.uom,
      width: o.height,
      length: o.width,
      height: o._height,
      swingTypeId: o.swingType,
      rotation: o.rotation,
      additionalOption1On: o.additionalOption1On,
      additionalOption1Type: o.additionalOption1Type,
      additionalOption1TypePrice: o.additionalOption1TypePrice,
      additionalOption2On: o.additionalOption2On,
      additionalOption2Type: o.additionalOption2Type,
      additionalOption2TypePrice: o.additionalOption2TypePrice,
      manufacturerSubProductSkuId: o.manufacturerSubProductSKUId,
      allowedSkuAddOnId:  context === constants.FPContext.skuTemplate
        ? o.allowedSkuAddOnId
        : null
    };
  });
  return mappedOptions;
};

const mapSelectedAddonOptionsFromFP = (options, context) => {
  return _.map(options, (o) => {
    let mOption = {
      addonId: o.selectedAddonId || o.addOnId,
      bundleId: o.bundleId,
      addOnTypeId: o.type,
      price: o.agreedPrice
        ? o.agreedPrice
        : o.price,
      unitsOfMeasureId: o.uom,
      width: o.height,
      length: o.width,
      height: o._height,
      rotation: o.rotation,
      showInFloorPlan: true,
      hasDimensions: (o.height || o.width) ? true : false,
      positionX: o.x,
      positionY: o.y,
      additionalOption1On: o.additionalOption1On,
      additionalOption2On: o.additionalOption2On,
      additionalOption1Type: o.additionalOption1 || false,
      additionalOption2Type: o.additionalOption2 || false,
      colorId: o.colorId,
      additionalOption1ColorId: o.additionalOption1ColorId,
      additionalOption2ColorId: o.additionalOption2ColorId,
      seq: o.seq || -1,
      id: o.referencedId || 0,
      uniqueAddOnId: o.uniqueAddOnId,
      typeOfUnitOfMeasure: o.uom
    };
    if (context === constants.FPContext.inventory) {
      mOption.name = o.name;
      mOption.swingTypeId = o.swingType || 0;
      mOption.additionalOptionType1Price = o.additionalOptionType1Price || o.additionalOption1Price;
      mOption.additionalOptionType2Price = o.additionalOptionType2Price || o.additionalOption2Price;
    } else {
      mOption.addOnName = o.name;
      mOption.typeOfSwing = o.swingType || 0;
      mOption.allowedSkuAddOnId = o.allowedSkuAddOnId || 0;
      if (!mOption.id) {
        mOption.id = 0;
      }
      if (!mOption.typeOfSwing) {
        mOption.typeOfSwing = null;
      }
      mOption.additionalOption1Price = o.additionalOptionType1Price || o.additionalOption1Price;
      mOption.additionalOption2Price = o.additionalOptionType2Price || o.additionalOption2Price;
      // have we already successfully parsed these values???
      if (mOption.additionalOption1On !== true && mOption.additionalOption1On !== false) {
        mOption.additionalOption1On = mOption.additionalOption1Type;
        mOption.additionalOption2On = mOption.additionalOption2Type;
      }
      delete mOption.additionalOptionType1Price;
      delete mOption.additionalOptionType2Price;
      delete mOption.additionalOption1Type;
      delete mOption.additionalOption2Type;
      mOption.wall = 0;
      mOption.addOnId = mOption.addonId;
      delete mOption.addonId;
    }
    return mOption;
  });
};

const mapExteriorFromFP = (model, context) => {
  if (!model) return;
  let width = model.height ? model.height / 12 : null;
  let height = model._height ? model._height / 12 : null;
  let length = model.width ? model.width / 12 : null;

  let mappedModel = {
    id: model.id,
    porchColorId: model.porchColorId,
    porchTypeId: model.porchType,
    porchMin: (model.porchMin || 0),
    porchMax: (model.porchMax || 0),
    porchWall1Length: (model.porchWall1Length || 0),
    porchWall2Length: (model.porchWall2Length || 0),
    porchWall3Length: (model.porchWall3Length || 0),
    porchWall4Length: (model.porchWall4Length || 0),
    price: model.price
  };
  if (context === constants.FPContext.skuTemplate) {
    if (!mappedModel.id) {
      mappedModel.id = 0;
    }
    mappedModel.width = width;
    mappedModel.length = length;
    mappedModel.height = height;
    mappedModel.manufacturerSubProductId = 0;
    mappedModel.displayName = null;
    mappedModel.defaultPrice = 0.0;
    mappedModel.basePrice = 0.0;
  } else {
    if (model.isOther) {
      mappedModel.unitWidthFeetOtherText = width;
      mappedModel.unitLengthFeetOtherText = length;
      mappedModel.unitHeightFeetOtherText = height;
    } else {
      mappedModel.unitWidthFeet = width;
      mappedModel.unitLengthFeet = length;
      mappedModel.unitHeightFeet = height;
    }
  }
  return mappedModel;
};

const mapAddonOptionsForFP = (options, context) => {
  let mappedOptions = _.map(options, (o) => {
    return {
      addOnId: o.addOnId,
      addonOptionId: o.id,
      bundleId: o.bundleId || null,
      name: o.name ? o.name : o.addOnName,
      type: o.addOnTypeId,
      agreedPrice: o.agreedPrice,
      price: o.price,
      uom: o.unitOfMeasureId || o.typeOfUnitOfMeasure || 1,
      width: o.length || 0,
      _height: o.height || 0,
      height: o.width || 0,
      swingType: o.swingTypeId || 0,
      rotation: o.rotation || 0,
      additionalOption1On: o.additionalOption1On,
      additionalOption2On: o.additionalOption2On,
      additionalOption1Type: o.additionalOption1Type || o.additionalOptionType1 || 0, //windows only
      additionalOption1Price: o.additionalOption1TypePrice || o.additionalOptionType1Price || 0, //windows only
      additionalOption2Type: o.additionalOption2Type || o.additionalOptionType2 || 0, //windows only
      additionalOption2Price: o.additionalOption2TypePrice || o.additionalOptionType2Price || 0, //windows only
      skuId: o.skuId,
      allowedSkuAddOnId: context === constants.FPContext.skuTemplate ? o.id : null,
      isDefault: o.isDefault,
      // manufacturerSubProductSKUId: o.manufacturerSubProductSkuId,
      // manufacturerSubProductSKUAddOnId:
      //   context === constants.FPContext.skuTemplate ? o.id : null,
    };
  });
  return mappedOptions;
};

const mapBundleAddonOptionsForFP = (options, bundlePrice, context) => {
  const mappedOptions = _.map(options, (o) => {
    const addOn = {
      addOnId: o.addOnId,
      addonOptionId: o.id,
      bundleId: o.bundleId,
      name: o.name ? o.name : o.addOnName,
      type: o.addOnTypeId,
      agreedPrice: o.agreedPrice,
      price: o.price,
      uom: o.unitOfMeasureId || o.typeOfUnitOfMeasure || 1,
      width: o.length || 1,
      _height: o.height || 0,
      height: o.width || 0,
      swingType: o.swingTypeId || 0,
      rotation: o.rotation || 0,
      additionalOption1On: o.additionalOption1On,
      additionalOption1Type: o.additionalOption1Type || 0,
      additionalOption1TypePrice: o.additionalOption1TypePrice || 0,
      additionalOption2On: o.additionalOption2On,
      additionalOption2Type: o.additionalOption2Type || 0,
      additionalOption2TypePrice: o.additionalOption2TypePrice || 0,
      skuId: o.skuId,
      allowedSkuAddOnId: o.allowedSkuAddOnId,
      bundlePrice: bundlePrice || 0,
      isDefault: o.isDefault,
    };
    return addOn;
  });
  return mappedOptions;
};

export default {
  defaultRulerOffset: 3,
  defaultFontSize: 4,
  mapAddonOptionsFromFP: mapAddonOptionsFromFP,
  mapAddonOptionsForFP: mapAddonOptionsForFP,
  mapSelectedAddonOptionsFromFP: mapSelectedAddonOptionsFromFP,
  mapExteriorFromFP: mapExteriorFromFP,
  mapExteriorForFP(model, context) {
    if (!model) return;
    let mappedModel = {};
    let width = null;
    let height = null;
    let length = null;
    if (context === constants.FPContext.skuTemplate) {
      width = model.width;
      length = model.length;
      height = model.height;
    } else {
      width = model.unitWidthFeet && model.unitWidthFeet > 0
          ? model.unitWidthFeet
          : !isNaN(parseInt(model.unitWidthFeetOtherText))
          ? parseInt(model.unitWidthFeetOtherText)
          : 0;
      length = model.unitLengthFeet && model.unitLengthFeet > 0
          ? model.unitLengthFeet
          : !isNaN(parseInt(model.unitLengthFeetOtherText))
          ? parseInt(model.unitLengthFeetOtherText)
          : 0;
      height = model.unitHeightFeet && model.unitHeightFeet > 0
          ? model.unitHeightFeet
          : !isNaN(parseInt(model.unitHeightFeetOtherText))
          ? parseInt(model.unitHeightFeetOtherText)
          : 0;
      mappedModel.isOther = model.unitManufacturerSubProductSku && model.unitManufacturerSubProductSku.label === constants.OTHER;
    }
    mappedModel.id = model.id;
    mappedModel.width = length ? length * 12 : 0;
    mappedModel.height = width ? width * 12 : 0; //represents length in fp
    mappedModel._height = height ? height * 12 : 0; //represents building height
    mappedModel.porchColorId = model.porchColorId;
    mappedModel.porchType = model.typeOfPorch || constants.PORCH_TYPES.NONE.value;
    // porch dimensions are always in inches
    mappedModel.porchMin = (model.porchMin || 0);
    mappedModel.porchMax = (model.porchMax || 0);
    mappedModel.porchWall1Length = (model.porchWall1Length || 0);
    mappedModel.porchWall2Length = (model.porchWall2Length || 0);
    mappedModel.porchWall3Length = (model.porchWall3Length || 0);
    mappedModel.porchWall4Length = (model.porchWall4Length || 0);
    mappedModel.basePrice = model.agreedPrice
      ? model.agreedPrice
      : model.basePrice; //sku base price
    mappedModel.price = model.retailPrice ? model.retailPrice : model.basePrice;
    mappedModel.standardRetailPrice = model.standardRetailPrice;
    mappedModel.standardSkuBasePrice = model.standardSkuBasePrice;
    return mappedModel;
  },
  mapSelectedAddonOptionsForFP(options, context, exteriorHeightInches = 0) {
    return _.map(options, (o) => {
      return {
        addOnId: o.addOnId,
        selectedAddonId: o.addOnId,
        name: o.name ? o.name : o.addOnName,
        bundleId: o.bundleId || null,
        type: o.addOnTypeId || 0,
        agreedPrice: o.agreedPrice,
        price: o.price,
        uom: o.unitOfMeasureId || o.typeOfUnitOfMeasure || 1,
        width: o.length || 0,
        _height: o.height || 0,
        height: o.width || exteriorHeightInches || 0,
        swingType:  o.swingTypeId || o.typeOfSwing || 0,
        rotation: o.rotation || 0,
        x: o.positionX || 0,
        y: o.positionY || 0,
        additionalOption1On: o.additionalOption1On,
        additionalOption2On: o.additionalOption2On,
        additionalOption1Type: o.additionalOption1Type,
        additionalOption2Type: o.additionalOption2Type,
        additionalOption1Price: context === constants.FPContext.skuTemplate ? o.additionalOption1Price : o.additionalOptionType1Price || o.additionalOption1Price,
        additionalOption2Price:  context === constants.FPContext.skuTemplate ? o.additionalOption2Price : o.additionalOptionType2Price || o.additionalOption2Price,
        colorId: o.colorId,
        additionalOption1ColorId: o.additionalOption1ColorId,
        additionalOption2ColorId: o.additionalOption2ColorId,
        allowedSkuAddOnId: o.allowedSkuAddOnId,
        referencedId: o.id,
        seq: o.seq,
        uniqueAddOnId: o.uniqueAddOnId,
        isDefault: o.isDefault,
      };
      // if(context === constants.FPContext.skuTemplate){
      //   item.additionalOption1Type = o.additionalOption1;
      //   item.additionalOption2Type = o.additionalOption2;
      // } else {
      //   item.additionalOption1Type = o.additionalOptionType1;
      //   item.additionalOption2Type = o.additionalOptionType2;
      // }
      // return item;
    });
    //return mappedOptions;
  },

  mapBundlesForFP(options, context) {
    let mappedOptions = _.map(options, (o) => {
        let addons = mapBundleAddonOptionsForFP(o.addOns, o.price, context);
      let bundle = {
        ...o,
        name: o.bundleName,
        addOns: addons,
        basePrice: o.price
      };
        return bundle;
    });
    return mappedOptions;
  },

  saveFloorPlanChanges(
    uiContext,
    exterior,
    selectedAddOns,
    rulerOffset,
    fontSize,
    imgUrl,
    callbackfunc = null,
    idList = null,
    unitPrice = null,
    skuId = null
  ) {
    let ext = mapExteriorFromFP(exterior, uiContext);
    const selectedAddOnList = mapSelectedAddonOptionsFromFP(selectedAddOns, uiContext);
    if (uiContext === constants.FPContext.skuTemplate) {
      const payload = {
        mfgSubProductSku: ext,
        subProductSkuTemplateAddOns: selectedAddOnList,
        fontSize: fontSize,
        rulerOffset: rulerOffset
      };
      api.post("Manufacturer/SaveSKUDefaultTemplate", payload).then((res) => {
        if (res.data) {
          Alert.success("Your changes have been saved successfully!");
          if (callbackfunc) {
            callbackfunc();
          }
        } else {
          Alert.error(
            "There was an error saving your changes. Please try again."
          );
        }
      });
    }
    else if (uiContext === constants.FPContext.contract) {
      const payload = {
        contractId: ext.id,
        unitPrice: unitPrice,
        skuId: skuId,
        porchDetails: {
          porchColorId: ext.porchColorId,
          porchTypeId: ext.porchTypeId,
          typeOfPorch: ext.porchTypeId,
          porchMin: ext.porchMin,
          porchMax: ext.porchMax,
          porchWall1Length: ext.porchWall1Length,
          porchWall2Length: ext.porchWall2Length,
          porchWall3Length: ext.porchWall3Length,
          porchWall4Length: ext.porchWall4Length,
        },
        SelectedInventoryAddOns: selectedAddOnList,
        fontSize: fontSize,
        rulerOffset: rulerOffset,
        price: ext.price,
        InventoryIdList: null
      };
      api.post("CashSale/SaveQuoteFloorplanChanges", payload).then((res) => {
        if (res.data.success) {
          Alert.success("Your changes have been saved successfully!");
          const base64image = imgUrl.split(";base64,")[1];
          let formData = new FormData();
          formData.append("ContractId", ext.id);
          formData.append("TypeOfAttachment", constants.ATTACHMENT_TYPE_IDS.InventoryFloorPlan);
          formData.append("ImageString", base64image);
          api.post_form_data("Contract/UploadFloorPlanImage", formData).then((r) => {
            if (!r.data.success) {
              console.error(r.data.message);
            }
            if (callbackfunc) {
              callbackfunc(res.data.message.newPrice, selectedAddOnList);
            }
          }).catch((e) => console.error(e));
        } else {
          Alert.error(res.data.message);
        }
      });
    }
    else
    {
      const payload = {
        inventoryId: ext.id,
        porchDetails: {
          porchColorId: ext.porchColorId,
          porchTypeId: ext.porchTypeId,
          typeOfPorch: ext.porchTypeId,
          porchMin: ext.porchMin,
          porchMax: ext.porchMax,
          porchWall1Length: ext.porchWall1Length,
          porchWall2Length: ext.porchWall2Length,
          porchWall3Length: ext.porchWall3Length,
          porchWall4Length: ext.porchWall4Length,
        },
        SelectedInventoryAddOns: selectedAddOnList,
        fontSize: fontSize,
        rulerOffset: rulerOffset,
        imgUrl: imgUrl,
        price: ext.price,
        InventoryIdList: idList
      };
      const url = idList && idList.length
        ? "Inventory/SaveFloorplanChangesMulti"
        : "Inventory/SaveFloorplanChanges";
      api.post(url, payload).then((res) => {
        if (res.data.success) {
          Alert.success("Your changes have been saved successfully!");
          const base64image = imgUrl.split(";base64,")[1];
          let formData = new FormData();
          if (idList && idList.length){
            formData.append("UnitInventoryIdStringList", JSON.stringify(idList));
          }
          formData.append("UnitInventoryId", ext.id);
          formData.append(
            "TypeOfAttachment",
            constants.ATTACHMENT_TYPE_IDS.InventoryFloorPlan
          );
          formData.append("ImageString", base64image);
          const saveImgUrl = idList && idList.length ? "Inventory/UploadFloorPlanImageMulti" : "Inventory/UploadFloorPlanImage";
          api.post_form_data(saveImgUrl, formData).then((r) => {
            if (!r.data.success) {
              console.error(r.data.message);
            }
            if (callbackfunc) {
              callbackfunc(ext.id);
            }
          }).catch((e) => console.error(e));
        } else {
          Alert.error(res.data.message);
        }
      });
    }
  },

  getAddOns(mfgId, skuId = null) {
    if (!mfgId) return;
    if (skuId > 0) {
      api.fetch(`Manufacturer/ListSkuAddOns/${mfgId}/${skuId}`).then((r) => {
        if (!r.data.success) return [];
        // callback(r.data.message);
        return r.data.message;
      });
    }
    api
      .fetch("Manufacturer/ListMfgAddons/" + mfgId)
      .then((r) => {
        if (!r.data.success) return [];
        //callback(r.data.message);
        return r.data.message;
      })
      .catch(api.catchHandler);
  },
};
