import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import {
    Card,
    Col,
    Row,
    Input,
    Spinner,
    Table,
    ModalBody,
    ModalFooter,
    Modal,
    ModalHeader,
    CardHeader,
    Button,
    ButtonGroup,
    FormGroup,
    Label,
    Form,
    CardBody,
    AccordionItem,
    AccordionHeader,
    AccordionBody,
    Accordion, Badge
} from "reactstrap";
import { api, constants } from "../utils";
import classNames from "classnames";
import Alert from "react-s-alert-v3";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CurrencyInput from "react-currency-input-field";
import {ui_helpers} from "../helpers";
import {ConfirmDeleteModal} from "./index";
import Select from "react-select";

const defaultSkuAddOn = {
    id: 0,
    addOn: null,
    isAssociated: false,
};

const AddOnCard = (props) => {
  const [addOn, setAddOn] = useState({});
  const [bundleAddOn, setBundleAddOn] = useState({});

  useEffect(() => {
    if(!props.a && !props.bundleAddOn) return;
    props.a.isLoading = false;
    setAddOn(props.a);
    setBundleAddOn(props.bundleAddOn);
    return () => {
      setAddOn({});
      setBundleAddOn({});
    };
  }, [props]);

    const cardColor = classNames({
      'primary': bundleAddOn ? bundleAddOn.isAssociated : false,
    });

    const cardClass = classNames('text-dark', 'm-2',
        'p-3', {
        'text-white': bundleAddOn ? bundleAddOn.isAssociated : false});

    const handleAddOnSelect = (associate, newQty = null) => {
      const payload = {
        id: bundleAddOn.bundleAddOnId || 0,
        //manufacturerSubProductSKUAddOnId: addOn.id,
        // manufacturerSubProductSKUBundleId: props.bundleId,
        allowedSkuAddOnId: addOn.id,
        allowedSkuBundleId: props.bundleId,
        manufacturerId: props.mfgId,
        qty: (newQty || bundleAddOn.qty || 1),
        deactivatedAt: associate ? null : new Date()
      };
      api.post('manufacturer/SaveSKUBundleAddOn', payload).then(r => {
        if(!r.data.success) return;
        props.getAssociation();
      }).catch(err => console.error(err));
    };

    const handleLoading = () => {
      let newAddon = Object.assign({}, addOn);
      newAddon.isLoading = true;
      setAddOn(newAddon);
    };

    if (!addOn) return null;
    return (
    <Card color={cardColor} className={cardClass}>
      {addOn.isLoading 
        ? (<Spinner size="sm" />)
        : (<Fragment>
            {bundleAddOn && bundleAddOn.isAssociated
              ? (<Input
                  type="number"
                  min="1"
                  style={{width: "65px", float: "left"}}
                  value={bundleAddOn ? bundleAddOn.qty : 1}
                  // onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    const newQty = parseInt(e.target.value, 10);
                    if (newQty <= 0) return;
                    handleAddOnSelect(true, newQty);
                  }}
                />)
              : null
            }
            <Input
              type="checkbox"
              style={{ cursor: "pointer", fontSize: "200%", float: "left" }}
              checked={bundleAddOn ? bundleAddOn.isAssociated : false}
              onChange={(e) => {
                handleAddOnSelect(e.target.checked);
                e.stopPropagation();
              }}
            />
          </Fragment>)
      }
      <span style={{ pointerEvents: "none" }}>
        {addOn.name} {addOn.isDefault ? "(Default Only Add-On)" : null}
      </span>
    </Card>);
};

const SKUBundleRow = ({item, index, mfgId, addOns, getAssociation, associations, closeDeleteModal, getBundles}) => {
  const [bundleItem, setBundleItem] = useState({});
  const [skuAddOns, setSkuAddons] = useState([]);
  const [tainted, setTainted] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    if(!item) return;
    setBundleItem(item);
    getAssociation();
  }, [item]);

    function getBundleAddOnTotal() {
      const foundItems = _.filter(associations, {'isAssociated': true});
      if (!foundItems) return 0;
      return foundItems.length;
    }

    function updateBundleItem() {
      api.fetch(`manufacturer/GetOneSKUBundle/${bundleItem.id}`).then(r => {
        if(!r.data.success) return;
        setBundleItem(r.data.message);
      }).catch(err => console.error(err));
    }

    function handleBundleItemChange(fieldName, fieldValue) {
      setTainted(true);
      let newBundle = Object.assign({}, bundleItem);
      newBundle[fieldName] = fieldValue;
      setBundleItem(newBundle);
    }

    function onBlur(price) {
      if(!tainted) return;
      let payload = Object.assign({}, bundleItem);
      payload.price = parseFloat(payload.price);
      payload.manufacturerId = mfgId;
      api.post('manufacturer/SaveSKUBundle', payload).then(r => {
        if (!r.data.success) return;
        setSaveSuccess(true);
        setTimeout(() => setSaveSuccess(false), 1000);
        updateBundleItem();
        setTainted(false);
      }).catch((err) => console.error(err));
    }

    function onBundleDelete() {
      api.fetch(`manufacturer/DeleteSKUBundle/${mfgId}/${bundleItem.id}`).then(r => {
        if(!r.data.success) return;
        getBundles();
      }).catch(err => console.error(err))
      .finally(() => setDeleteModal(false));
    }

    function onCopyBundle() {
      api.fetch(`manufacturer/CopyBundle/${mfgId}/${bundleItem.id}`).then(r => {
        if (r.data.success) {
          Alert.success("This bundle has now been copied to all other SKUs for all products with an association to this bundle");
        } else {
          Alert.warning(r.data.message);
        }
      }).catch(err => {
        Alert.error("An unexpected error occurred copying the bundle.");
        console.error(err);
      });
    }

    return (<>
  <ConfirmDeleteModal open={deleteModal} onDeleteCallback={onBundleDelete} onCancelCallback={() => setDeleteModal(false)}/>
  <AccordionItem>
    <AccordionHeader targetId={index}>
      <Row className={'d-flex align-items-center w-100'}>
        <Col xs={8}>
          <Row className={'d-flex align-items-center w-100'}>
            <Col sm={10}>
              <strong className={'text-nowrap'}>{bundleItem && bundleItem.bundleName && ui_helpers.replaceTextIfTooLong(80, bundleItem.bundleName)}
              </strong>
            </Col>
            <Col sm={2}>
              <CurrencyInput prefix={'$'}
               className={classNames('form-control', {'border-success': saveSuccess === true})}
               name='price'
               style={{textAlign: "right"}}
               decimalsLimit={2}
               value={bundleItem.price}
               onBlur={onBlur}
               onValueChange={(value) => handleBundleItemChange('price', value)}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={1}>
          <Badge color='primary'>
            {addOns && associations && getBundleAddOnTotal()}
          </Badge>
          <strong style={{marginLeft: "10px"}}>Items</strong>
        </Col>
        <Col xs={2} className={'d-flex justify-content-end me-1'}>
          <Button color="success" className="text-white me-2" onClick={(e) => {
              e.stopPropagation();
              onCopyBundle();
            }}>
              <FontAwesomeIcon icon='copy' />
          </Button>
          <Button className={'btn-delete'} onClick={(e) => {
              e.stopPropagation();
              setDeleteModal(true);
            }}>
              <FontAwesomeIcon icon='times-circle' />
          </Button>
        </Col>
      </Row>
    </AccordionHeader>
    <AccordionBody accordionId={index}>
      <div className="d-flex flex-wrap">
      {addOns && addOns.length && associations
        ? _.map(_.reject(addOns, ao => ao.unitOfMeasureId === constants.UNITS_OF_MEASURE_ID.PERCENT_OF_BASE),s => 
          (<AddOnCard
            a={s} 
            bundleId={bundleItem.id}
            getAssociation={getAssociation}
            mfgId={mfgId}
            bundleAddOn={
              _.find(
                _.orderBy(associations, ['isAssociated', 'name'], ['asc', 'asc']), 
                  a => s.id === a.skuAddonId)}
          />))
        : null
      }
      </div>
    </AccordionBody>
  </AccordionItem>
</>);
};

const defaultBundleItem = {
    id: 0,
    bundleId: 0,
    price: 0.00,
    manufacturerSubProductSkuId: 0
};

function EditSkuBundleOpts(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [activeAccordion, setActiveAccordion] = useState("0");
  const [bundles, setBundles] = useState([]);
  const [skuBundles, setSkuBundles] = useState([]);
  const [skuBundleItem, setSkuBundleItem] = useState(defaultBundleItem);
  const [addOns, setAddOns] = useState([]);
  const [addNewBundle, setAddNewBundle] = useState(false);
  const [addonAssociation, setAddOnAssociation] = useState([]);
  const [addOnOptions, setAddOnOptions] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);

useEffect(() => {
  if(!props) return;
  getSubProductBundles();
  getSKUBundles();
  getSKUAddons();
}, [props]);

function getSKUAddons() {
  api.fetch(`manufacturer/ListMfgSkuAddOnsForBundles/${props.mfgId}/${props.sku.id}`).then(r => {
    if(!r.data.success) return;
    setAddOns(r.data.message.addons);
    setAddOnAssociation(r.data.message.addOnAssociations)
  }).catch(err => console.error(err));
}

function getBundleAddOnAssociations() {
  api.fetch(`manufacturer/GetSKUBundleAddOnAssociations/${props.sku.id}`).then(r => {
    if(!r.data.success) return;
    setAddOnAssociation(r.data.message)
  }).catch(err => console.error(err));
}

function getSKUBundles() {
  const payload = {
    activeOnly: true,
    manufacturerSkuId: props.sku.id,
  }
  api.post('manufacturer/ListSKUBundles', payload).then(r => {
    if(!r.data) return;
    setSkuBundles(r.data);
  }).catch(err => console.error(err));
}

function getSubProductBundles() {
  api.fetch(`manufacturer/ListSubProductBundles/${props.mfgId}/${props.subProductId}`).then(r => {
    if(!r.data) return;
    let mappedBundles = _.map(r.data, b => {
      b.value = b.id;
      b.label = b.name;
      return b;
    });
    setBundles(mappedBundles);
  }).catch(err => console.error(err));
}

  function addBundle() {
    if (skuBundleItem.bundleId == 0) {
      Alert.error("You must select a Product Bundle Item");
      return;
    }
    const payload = {
      ...skuBundleItem,
      manufacturerSubProductSkuId: props.sku.id,
      manufacturerId: props.mfgId
    };
    api.post('manufacturer/SaveSKUBundle', payload).then(r => {
      if (!r.data.success) {
        Alert.error(r.data.message);
        return;
      }
      getSKUBundles();
      setAddNewBundle(false);
      setSkuBundleItem(defaultBundleItem);
    }).catch(err => console.error(err));
  }

  function onBundleItemChange(fieldName, fieldValue) {
      let newBundleItem = Object.assign({}, skuBundleItem);
      newBundleItem[fieldName] = fieldValue;
      setSkuBundleItem(newBundleItem);
  }

  function toggleAccordion(targetId) {
    targetId === activeAccordion
        ? setActiveAccordion("0")
        : setActiveAccordion(targetId);
  }

  // function handleDeleteItem(boolValue) {
  //   setConfirmDelete(boolValue);
  // }

  return (<>
    <Modal isOpen={addNewBundle} centered>
      <ModalHeader>
        Add Bundle Option
      </ModalHeader>
      <ModalBody>
        <Form>
            <FormGroup>
                <Label>
                    Price
                </Label>
                <CurrencyInput value={skuBundleItem.price}  name={'price'}
                   onValueChange={(value, name) =>
                       onBundleItemChange(name, value)}
                   prefix={'$'} decimalsLimit={2} className={'form-control'}/>
            </FormGroup>
            <FormGroup>
                <Label>
                    Bundle
                </Label>
                <Select
                  options={_.map(bundles, b => {
                    b.value = b.id;
                    b.label = b.name;
                    return b;
                  })}
                  value={_.find(bundles, b => {return b.id === skuBundleItem.bundleId;})}
                  onChange={(e) => onBundleItemChange('bundleId', Number(e.value))}
                  defaultValue={{label: "Select a Bundle", value: 0}}
                />
                {/*<Input type={'select'}*/}
                {/*       name={'bundleId'}*/}
                {/*       value={skuBundleItem.bundleId}*/}
                {/*       onChange={}>*/}
                {/*    {bundles.length  ? null : <option value='' disabled>' No Bundles '</option>}*/}
                {/*    {_.map(bundles, (x) => (*/}
                {/*        <option key={x.value} value={x.value}>*/}
                {/*            {x.label}*/}
                {/*        </option>*/}
                {/*    ))}*/}
                {/*</Input>*/}
            </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup className="float-end">
          <Button onClick={() => setAddNewBundle(false)}>
            Cancel
          </Button>
          <Button className='bg-primary border-primary' onClick={addBundle}>
            Save
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
    <Row>
      <Col>
        <h3>Bundles Available for this SKU</h3>
      </Col>
      <Col>
        <Button size='sm' title="Make another bundle available for this SKU" onClick={() => setAddNewBundle(true)} className='bg-success border-success float-end'>
          <FontAwesomeIcon icon='plus' /> Associate Bundle
        </Button>
      </Col>
    </Row>
    <Row className={'mt-2'}>
      <Col>
        <Accordion
          open={activeAccordion}
          toggle={toggleAccordion}>
          {skuBundles.length && addonAssociation
          ? _.map(skuBundles, (s, idx) =>
            <SKUBundleRow
              item={s}
              key={`bundle-${idx}`}
              index={idx}
              addOns={addOns}
              mfgId={props.mfgId}
              getAssociation={getBundleAddOnAssociations}
              getBundles={getSKUBundles}
              associations={_.filter(addonAssociation, a => a.skuBundleId == s.id)}
            />)
          : (<Row>
              <Col>
                <span>
                  This SKU does not have any Bundles.
                </span>
              </Col>
            </Row>)}
        </Accordion>
      </Col>
      </Row>
    </>
  );
}

export default EditSkuBundleOpts;
