import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Row, Col, Label } from "reactstrap";
import moment from "moment";
import DatePicker from 'react-datepicker';
import {api, constants} from "../utils";
import { date_helpers, ui_helpers, floorplan_helpers } from "../helpers";
import Alert from "react-s-alert-v3";
import Loading from "./Loading";
import FloorplanTool from "../floorplan-module-2.0/FloorplanTool";
import _ from "lodash";

const popOutStyle = {
  position: "fixed",
  width: "100vw",
  height: "100vh",
  top: 0,
  left: 0,
  backgroundColor: "#efefef",
  zIndex: 900,
};

function ContractBuild({ subProductId, contractId, subProductSkuId, subProductSkuName, serialNumber, inventoryId, 
    setInventoryId, refreshPrice, setContractSerial, onSaveContract, autoShowEditor, setAutoShowEditor }) {
  const [showEditor, setShowEditor] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [inventory, setInventory] = useState({});
//For FP
  const [selectedFPAddOns, setSelectedFPAddOns] = useState([]);
  const [mfgFPAddOnOptions, setMfgFPAddOnOptions] = useState([]);
  const [optionColors, setOptionColors] = useState([]);
  const [bundleOptions, setBundleOptions] = useState([]);
  const [hasExistingFloorPlan, setHasExistingFloorPlan] = useState(false);

  function getInventory() {
    setIsLoading(true);
    return api.fetch(`Inventory/GetInventoryByContractId/${contractId}`).then(r => {
      if (!r.data) return;
      setInventory(r.data);
      if (!inventoryId) {
        setInventoryId(r.data.id);
      }
      // if (!r.data.unitManufacturerSubProductSkuId) return;
      // if (!r.data.manufacturerBrandId) return;
      // api.fetch(`Inventory/GetMfgProductSkuAddOnOptions/${r.data.manufacturerBrandId}/${r.data.unitManufacturerSubProductSkuId}`).then(r => {
      //   if (!r.data || !r.data.data) return;
      //   setAddOns(r.data.data);
      // });
    }).catch((err) => console.error(err))
    .finally(() => setIsLoading(false));
  }

  useEffect(getInventory, []);

  useEffect(() => {
    if (autoShowEditor) {
      if (inventory && inventoryId) {
        onShowEditor().then(() => {
          setAutoShowEditor(false);
        }).catch(err => {
          console.error(err)
        });
      }
    }
  }, [autoShowEditor, inventory, inventoryId])

  async function getInitialData() {
    setIsLoading(true);
    // const res = await api.fetch(`Manufacturer/ListMfgSubProductAddOns/${subProductId}`);
    // if (res.data.success) setAddOns(res.data.data);
    const res2 = await api.fetch(`Inventory/GetInventoryByContractId/${contractId}`);
    if (res2.data) setInventory(res2.data);
    setIsLoading(false);
  }

  function onDueDateChange(d) {
    if (!inventory) {
      console.warn('missing inventory, cannot set dueon');
      return;
    }
    const payload = {
      id: inventory.id,
      d: date_helpers.formatDateForServer(d)
    };
    api.post('inventory/SetBuildDueOn', payload).then(r => {
      if (r.data.success) {
        setInventory({...inventory, buildDueOn: d});
      }
      else {
        Alert.error('There was an error attempting to update the due date: ' + r.data.message);
      }
    }).catch(err => {
      console.error(err);
      Alert.error('There was an error attempting to update the due date.');
    });
  }

  function resolveModelName() {
    return subProductSkuId && subProductSkuId !== constants.OTHER_ID
      ? subProductSkuName
      : serialNumber;
  }

  function onShowEditor() {
    const mfgId = inventory && inventory.manufacturerBrandId && inventory.manufacturerBrandId !== constants.OTHER_ID
      ? inventory.manufacturerBrandId
      : null;
    const skuId = subProductSkuId && subProductSkuId !== constants.OTHER_ID
      ? subProductSkuId
      : null;
    const payload = {
      ManufacturerId: mfgId,
      SubProductSKUId: skuId,
      InventoryId: inventoryId,
      ActiveOnly: true
    };

    return new Promise((resolve, reject) => {
    api
    .post("Reference/GetMfgListForFloorPlan", payload).then(res => {
      if (res.data.success) {
        let inv = _.cloneDeep(inventory);
        let selectedAddOnOptions = res.data.message.selectedAddOnOptions || [];
        selectedAddOnOptions = _.map(selectedAddOnOptions, selectedAddOn => {
          const matchingAddOn = _.find(res.data.message.addonOptions, addOn => addOn.addOnId === selectedAddOn.addOnId && addOn.isDefault);
          if (matchingAddOn) {
            selectedAddOn.isDefault = matchingAddOn.isDefault;
          }
          return selectedAddOn;
        });
        setSelectedFPAddOns(selectedAddOnOptions);
        const noDefaultAddOns = _.filter(res.data.message.addonOptions, addOn => !addOn.isDefault);
        setMfgFPAddOnOptions(noDefaultAddOns || []);
        setBundleOptions(res.data.message.bundleOptions || []);
        setOptionColors(ui_helpers.idNameToValueLabel(res.data.message.optionColors, null, false));
        setHasExistingFloorPlan(res.data.message.hasExistingFloorPlan);
        if (res.data.message.mfgSku) {
          const sku = res.data.message.mfgSku
          inv.typeOfPorch = sku.porchTypeId;
          inv.basePrice = sku.basePrice;
        } else if(res.data.message.inventory) {
          const newInv = res.data.message.inventory;
          inv.unitWidthFeet = newInv.resolvedWidthFeet;
          inv.unitLengthFeet = newInv.resolvedLengthFeet;
          inv.unitHeightFeet = newInv.resolvedHeightFeet;
          inv.basePrice = newInv.skuId ? newInv.basePrice : inv.retailPrice;
          inv.typeOfPorch = newInv.porchTypeId;
        }
        inv.agreedPrice = res.data.message.agreedPrice;
        inv.standardRetailPrice = res.data.message.inventory.standardRetailPrice;
        inv.standardSkuBasePrice = res.data.message.inventory.standardSkuBasePrice;
        setInventory(inv);
      }
      setShowEditor(true);
      resolve();
    })
    .catch(err => {
      console.error(err); 
      reject();
    })
  });
  }

  function onFPSave(exterior, selectedAddOns, rulerOffset, fontSize, imgUrl) {
    floorplan_helpers.saveFloorPlanChanges(
        constants.FPContext.inventory,
        exterior,
        selectedAddOns,
        fontSize,
        rulerOffset,
        imgUrl,
        (saveResult) => {
          refreshPrice(saveResult);
          if (onSaveContract) {
            onSaveContract();
          }
        },
        null
    );
  }

  function renderFloorplan() {
    const exterior = floorplan_helpers.mapExteriorForFP(inventory, constants.FPContext.inventory);
    const callType = constants.FPContext.inventory;
    return (
      <FloorplanTool
        editingContext={callType}
        exterior={exterior}
        optionColors={optionColors}
        addOnOpts={floorplan_helpers.mapAddonOptionsForFP(mfgFPAddOnOptions, callType)}
        selectedAddOns={floorplan_helpers.mapSelectedAddonOptionsForFP(selectedFPAddOns, callType, exterior.height)}
        saveCallback={onFPSave}
        rulerOffset={inventory.rulerOffSet || floorplan_helpers.defaultRulerOffset} //no inventory defined
        fontSize={inventory.fontSize || floorplan_helpers.defaultFontSize}
        onExit={() => setShowEditor(false)}
        bundleOptions={floorplan_helpers.mapBundlesForFP(bundleOptions, callType)}
        hasExistingFloorPlan={hasExistingFloorPlan}
        modelName={resolveModelName()}
      />);
  }

  if (isLoading) return (<Loading />);
  return (<>
      <div className="h-100 my-3 d-flex justify-content-center">
        <Row>
          <Col xs="4">
            <Label>Due On</Label>
          </Col>
          <Col>
            <div className="datePickerDiv">
              <DatePicker
                selected={inventory && inventory.buildDueOn ? moment(inventory.buildDueOn).toDate() : null}
                onChange={onDueDateChange}
                showMonthDropdown
                showYearDropdown
                isClearable
                withPortal
                dropdownMode="select"
                placeholderText=""
              />
            </div>
          </Col>
        </Row>
        <Button size="lg" color="dark" onClick={onShowEditor}>
          Launch Add-Ons &amp; Layout Editor{" "}<FontAwesomeIcon icon="arrow-right" />
        </Button>
      </div>
      {showEditor && (
        <div style={popOutStyle}>
          {renderFloorplan()}
        </div>)}
    </>
  );
}

export default ContractBuild;
