import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { api, constants } from "../utils";
import { floorplan_helpers } from "../helpers";
import { Button, Table, Row, Col, Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FloorplanTool from "../floorplan-module-2.0/FloorplanTool";
import Alert from "react-s-alert-v3";

const popOutStyle = {
  position: "fixed",
  width: "100vw",
  height: "100vh",
  top: 0,
  left: 0,
  //   padding: "1rem 2rem",
  backgroundColor: "#efefef",
  //   overflow: "auto",
  zIndex: 900,
};

function EditSKUOnsLayout({ sku, mfgId }) {
  const [addOns, setAddOns] = useState([]);
  const [defaultAddOns, setDefaultAddOns] = useState([]);
  const [showEditor, setShowEditor] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bundles, setBundles] = useState([]);
  const [mfgSku, setMfgSku] = useState(sku);
  ////For FP
  //const [selectedFPAddOns, setSelectedFPAddOns] = useState([]);
  //const [mfgFPAddOnOptions, setMfgFPAddOnOptions] = useState([]);

  useEffect(() => {
    getDefaultAddOns();
    getAddOns();
    loadSku();
  }, []);

  const loadSku =()=>{
    api.fetch(`manufacturer/getsku/${mfgSku.id}`)
    .then(res => {
      if(res.data && res.data.success){
        setMfgSku(res.data.message);
      }
    })
  }

  function copyTemplateToSKUS() {
    setIsLoading(true);
    api
      .post(`manufacturer/copyBuildTemplate/${mfgSku.id}`, {})
      .then((r) => {
        if (!r.data.success) {
          Alert.error(r.data.message);
          return;
        }
        Alert.success(
          "Successfully copied all template items to the Larger SKUs"
        );
      })
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
  }

  async function getAddOns() {
    const res = await api.fetch(
      `Manufacturer/GetMfgAddOnsAndBundles/${mfgId}/${mfgSku.id}`)
    setAddOns(res.data.message.addons);
    setBundles(res.data.message.bundles);
  }

  async function getDefaultAddOns() {
    const res = await api.fetch(
      `Manufacturer/ListManufacturerSKUTemplateAddOns/${mfgId}/${mfgSku.id}`
    );
    setDefaultAddOns(res.data);
  }

  const refresh =()=>{
    loadSku();
    getAddOns();
  }
  const onFPSave = async (
    exterior,
    selectedAddOns,
    rulerOffset,
    fontSize,
    imgUrl
  ) => {
    floorplan_helpers.saveFloorPlanChanges(
      constants.FPContext.skuTemplate,
      exterior,
      selectedAddOns,
      fontSize,
      rulerOffset,
      imgUrl,
      refresh
    );
  };

  function onShowEditor() {
    api
      .fetch(
        `Manufacturer/ListManufacturerSKUTemplateAddOns/${mfgId}/${mfgSku.id}`
      )
      .then((res) => {
        setDefaultAddOns(res.data);
        setShowEditor(true);
      });
  }

  let exterior = null;
  if (showEditor && mfgSku) {
    exterior = floorplan_helpers.mapExteriorForFP(mfgSku, constants.FPContext.skuTemplate);
  }

  return (
    <>
      <h3>Layout Editor</h3>
      <Row className={"justify-content-start m-2"}>
        <Col sm={3}>
          <Row>
            <Button size="lg" color="dark" onClick={onShowEditor}>
              Launch Template Editor <FontAwesomeIcon icon="arrow-right" />
            </Button>
          </Row>
          <Row>
            {/*{addOns && addOns.length ?*/}
            {defaultAddOns && defaultAddOns.length ? (
              <Button
                size="lg"
                color="success"
                className={"mt-2 text-white"}
                onClick={copyTemplateToSKUS}
              >
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <>
                    {" "}
                    Copy Template To Larger <FontAwesomeIcon icon="copy" />
                  </>
                )}
              </Button>
            ) : null}
          </Row>
        </Col>
      </Row>
      {showEditor && (
        <div style={popOutStyle}>
          <FloorplanTool
            onExit={() => {
              getDefaultAddOns();
              setShowEditor(false);
            }}
            editingContext={constants.FPContext.skuTemplate}
            exterior={exterior}
            selectedAddOns={floorplan_helpers.mapSelectedAddonOptionsForFP(defaultAddOns, constants.FPContext.skuTemplate, exterior.height)}
            addOnOpts={floorplan_helpers.mapAddonOptionsForFP(addOns, constants.FPContext.skuTemplate)}
            saveCallback={onFPSave}
            rulerOffset={floorplan_helpers.defaultRulerOffset} //no inventory defined
            fontSize={floorplan_helpers.defaultFontSize}
            bundleOptions={floorplan_helpers.mapBundlesForFP(bundles)}
            hasExistingFloorPlan={true}
          />
        </div>
      )}
    </>
  );
}

export default EditSKUOnsLayout;
