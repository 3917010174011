import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  Badge,
  UncontrolledTooltip
} from "reactstrap";
import { v4 as uuid } from "uuid";
import {ui_helpers} from '../../helpers'
import { addOnTypes, unitsOfMeasure } from "../floorplanConstants";
import styles from "../floorplanTool.module.css";
import {
  useActions,
  useAddOns,
  useControlTarget,
  useExterior,
  useInfo,
  useAppState,
  useDisplaySettings,
} from "../hooks";
import _ from "lodash";
import { ImageModal, SidebarCollapse, AddOn, Exterior, Grid, Rulers } from "./";
import {
  calculateBundleTotal,
  calculateExteriorPrice,
  canRemoveAddOn,
} from "../utils";

const saveSvgAsPng = require("save-svg-as-png");

let windowXPos = 0;
let defaultX = 10;

function PrimarySidebar(_props) {
  const [appState, appStateDispatch] = useAppState();
  const [info] = useInfo();
  const [{ addOnOpts, selectedAddOns, bundleOpts }, addOnsDispatch] = useAddOns();
  const [controlTarget, setControlTarget] = useControlTarget();
  const [displaySettings] = useDisplaySettings();
  const [exterior, exteriorDispatch] = useExterior();
  const editingContext = _props.editingContext;
  const actions = useActions({ addOnsDispatch, exteriorDispatch });

  const [alertModalOpen, setAlertModalOpen] = useState(false);

  const [noteText, setNoteText] = useState("");
  const [noteTextPrice, setNoteTextPrice] = useState("");
  const [bundlePriceTotal, setBundlePriceTotal] = useState(0);
  const [tipVisible, setTipVisible] = useState(true)
  const padding = {
    top: displaySettings.rulerOffset + 2 * 12,
    right: 3 * 12,
    bottom: 3 * 12,
    left: displaySettings.rulerOffset + 12,
  };

  useEffect(() => {
    if (
      selectedAddOns.length > 0 &&
      selectedAddOns.some((o) => o.bundleId > 0)
    ) {
      let bundleTotal = calculateBundleTotal(selectedAddOns, bundleOpts);
      setBundlePriceTotal(bundleTotal);
    }
    if (defaultX === 10 && exterior?.width) {
      defaultX = exterior.width / 15;
    }
  }, []);

  function getAndIncrementX() {
    const xValue = defaultX;
    const increment = exterior.width / 15;
    const nextDefaultX = defaultX + increment;
    defaultX = nextDefaultX < exterior.width
      ? nextDefaultX
      : increment;
    return xValue;
  }

  function addAddOn(o) {
    const newAddOn = { ...o };
    const newId = uuid();
    newAddOn.domId = newId;
    // respect agreed pricing if it exists
    const existingAddOnTypePricing = _.find(selectedAddOns, sao => sao.addOnId === newAddOn.addOnId && sao.agreedPrice);
    if (existingAddOnTypePricing) {
      o.agreedPrice = existingAddOnTypePricing.agreedPrice;
    }
    if (newAddOn.type === addOnTypes.loft) {
      newAddOn.x = 0;
      newAddOn.height = exterior.height;
    } else if (
      newAddOn.type === addOnTypes.singleWorkbench ||
      newAddOn.type === addOnTypes.doubleWorkbench
    ) {
      newAddOn.x = exterior.width - newAddOn.height / 2;
      newAddOn.y = exterior.height / 2;
      newAddOn.rotation = 90;
    } else if (newAddOn.type === addOnTypes.window) {
      windowXPos += newAddOn.width + 12;
      if (windowXPos >= exterior.width) windowXPos = 0;
      newAddOn.x = windowXPos;
      newAddOn.y = exterior.height / 2;
      newAddOn.rotation = 0;
    } else if (
      newAddOn.type === addOnTypes.singleDoor ||
      newAddOn.type === addOnTypes.doubleDoor ||
      newAddOn.type === addOnTypes.outlet ||
      newAddOn.type === addOnTypes.doubleOutlet
    ) {
      newAddOn.x = exterior.width / 2;
      newAddOn.y = exterior.height / 2;
      newAddOn.rotation = 0;
    } else if (newAddOn.uom !== unitsOfMeasure.percentOfBase) {
      newAddOn.x = getAndIncrementX();
      newAddOn.y = exterior.height / 2;
    }
    // default the unit width and length here for add on types of "Other" and UOM of SqFt
    if (newAddOn.type === addOnTypes.other && newAddOn.uom === unitsOfMeasure.sqFt) {
      newAddOn.width = exterior.width;
      newAddOn.height = exterior.height;
    }
    // console.log('adding add-on', newAddOn)
    actions.addAddOn(newAddOn);
    setControlTarget(newId);
  }

  function removeAddOn(o) {
    if (!canRemoveAddOn(o, selectedAddOns, bundleOpts)) {
      if(o.bundleId){
        setAlertModalOpen(true);
      } 
    } else {
      actions.removeLast(o);
    }
  }

  function addBundle(bundleOpt) {
    bundleOpt.addOns.forEach((a) => addAddOn(a));
    setBundlePriceTotal(bundlePriceTotal + bundleOpt.basePrice);
  }

  function removeBundle(bundleOpt) {
    selectedAddOns
      .filter((s) => s.bundleId === bundleOpt.id)
      .forEach((a) => actions.removeAddOn(a));
    setBundlePriceTotal(bundlePriceTotal - bundleOpt.basePrice);
  }

  function isBundleAdded(bundleOpt) {
    return (
      bundleOpt.addOns &&
      bundleOpt.addOns.length &&
      bundleOpt.addOns.every((a) =>
        selectedAddOns.some(
          (s) => s.bundleId === bundleOpt.id && s.addOnId === a.addOnId
        )
      )
    );
  }

  function setAddOnOptClassName(option) {
    const currentAddOn = selectedAddOns.find((x) => x.domId === controlTarget);
    if (currentAddOn && currentAddOn.addOnId === option.addOnId) {
      return "text-nowrap overflow-hidden text-primary fw-bold";
    }
    return "text-nowrap overflow-hidden";
  }

  function resolvePrice(option, uom) {
    const isPercent = uom === unitsOfMeasure.percentOfBase;
    if (option.agreedPrice)
      return isPercent
        ? option.agreedPrice
        : ui_helpers.formatCurrency(option.agreedPrice)
    return isPercent
      ? option.price
      : ui_helpers.formatCurrency(option.price);
  }

  function getUOMDescription(uom) {
    switch (uom) 
    { 
      case unitsOfMeasure.percentOfBase:
        return " % of base";
      case unitsOfMeasure.linearFt:
        return " /ft.";
      case unitsOfMeasure.sqFt:
        return " /sq. ft.";
      case unitsOfMeasure.each:
      default:
        return " ea.";
    }
  }

  const {standardPriceTotal, resolvedPriceTotal } = calculateExteriorPrice(exterior, selectedAddOns, bundleOpts, addOnOpts);
  return (
    <>
      <div className={`${styles.primarySidebar} p-3 shadow `}>
        <Modal isOpen={alertModalOpen} size="sm">
          <ModalBody>
            This Add-On is a part of a bundle, to remove it please remove the
            bundle.
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => setAlertModalOpen(false)}>
              Okay
            </Button>
          </ModalFooter>
        </Modal>
        <h5 className="d-flex">
          <FontAwesomeIcon icon="info-circle" className="me-2 " id="addOnToolTip"/>
          Tip
        </h5>
        <UncontrolledTooltip
          placement="right"
          target="addOnToolTip"
          //trigger="click"
        >
          <p>{info.message}</p> 
        </UncontrolledTooltip>
        <hr />
        <SidebarCollapse title="Bundles">
          {bundleOpts && bundleOpts.length > 0 ? (
            <>
              {bundleOpts
                .sort((a, b) => {
                  const nameA = a.name.toUpperCase();
                  const nameB = b.name.toUpperCase();
                  return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
                })
                .map((bo) => (
                  <div
                    key={bo.bundleName}
                    className="d-flex justify-content-between mb-2"
                  >
                    <div
                      className="text-nowrap overflow-hidden"
                      title={bo.bundleName}
                    >
                      {bo.bundleName}{" "}
                      <small className="text-muted">({resolvePrice(bo)})</small>
                    </div>
                    <div>
                      <ButtonGroup size="sm">
                        {isBundleAdded(bo) ? (
                          <Button
                            color="light"
                            className="m-0 p-1 text-muted"
                            style={{ fontSize: ".75em" }}
                            onClick={() => removeBundle(bo)} //actions.removeLast(o)
                          >
                            <FontAwesomeIcon icon="minus" /> Remove
                          </Button>
                        ) : (
                          <Button
                            color="light"
                            className="m-0 p-1 text-muted"
                            style={{ fontSize: ".75em" }}
                            onClick={() => addBundle(bo)}
                          >
                            <FontAwesomeIcon icon="plus" /> Add
                          </Button>
                        )}
                      </ButtonGroup>
                    </div>
                  </div>
                ))}
            </>
          ) : null}
        </SidebarCollapse>
        <SidebarCollapse title="Doors">
          <div>
          {addOnOpts
            .filter((x) => !x.isDefault)
            .filter((x) =>
              [addOnTypes.singleDoor, addOnTypes.doubleDoor].includes(x.type)
            )
            ?.sort((a, b) => {
              const nameA = a.name.toUpperCase();
              const nameB = b.name.toUpperCase();
              return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
            })
            .map((o) => (
              <div key={o.name} className="d-flex justify-content-between mb-2">
                <div className={setAddOnOptClassName(o)} title={o.name}>
                  {o.name}{" "}
                  <small className="text-muted">
                    ({resolvePrice(o, o.uom)}{" "}{getUOMDescription(o.uom)})
                  </small>
                </div>
                <div>
                  <ButtonGroup size="sm">
                    <Button
                      color="light"
                      className="m-0 p-1 text-muted"
                      style={{ fontSize: ".75em" }}
                      onClick={() => removeAddOn(o)}
                    >
                      <FontAwesomeIcon icon="minus" />
                    </Button>
                    <div className="text-center px-1 d-flex align-items-center">
                      {selectedAddOns.reduce(
                        (total, x) =>
                          x.id === o.id && x.name === o.name
                            ? total + 1
                            : total,
                        0
                      )}
                    </div>
                    <Button
                      color="light"
                      className="m-0 p-1 text-muted"
                      style={{ fontSize: ".75em" }}
                      onClick={() => addAddOn(o)}
                    >
                      <FontAwesomeIcon icon="plus" />
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
          ))}

          {editingContext !== "skuTemplate" ? null : (
            <>
          <div style={{fontWeight: "bold", padding: 0, margin: 0, textDecoration: "underline"}}>Default Only Options:</div>
          {addOnOpts
            .filter((x) => x.isDefault)
            .filter((x) =>
              [addOnTypes.singleDoor, addOnTypes.doubleDoor].includes(x.type)
            )
            ?.sort((a, b) => {
              const nameA = a.name.toUpperCase();
              const nameB = b.name.toUpperCase();
              return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
            })
            .map((o) => (
              <div key={o.name} className="d-flex justify-content-between mb-2">
                <div className={setAddOnOptClassName(o)} title={o.name}>
                  {o.name}{" "}
                  <small className="text-muted">
                    ({resolvePrice(o, o.uom)}{" "}{getUOMDescription(o.uom)})
                  </small>
                </div>
                <div>
                  <ButtonGroup size="sm">
                    <Button
                      color="light"
                      className="m-0 p-1 text-muted"
                      style={{ fontSize: ".75em" }}
                      onClick={() => removeAddOn(o)}
                    >
                      <FontAwesomeIcon icon="minus" />
                    </Button>
                    <div className="text-center px-1 d-flex align-items-center">
                      {selectedAddOns.reduce(
                        (total, x) =>
                          x.id === o.id && x.name === o.name
                            ? total + 1
                            : total,
                        0
                      )}
                    </div>
                    <Button
                      color="light"
                      className="m-0 p-1 text-muted"
                      style={{ fontSize: ".75em" }}
                      onClick={() => addAddOn(o)}
                    >
                      <FontAwesomeIcon icon="plus" />
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            ))}
            </>
          )}
          </div>
        </SidebarCollapse>
        <SidebarCollapse title="Windows">
          <div>
          {addOnOpts
            .filter((x) => !x.isDefault)
            .filter((x) => [addOnTypes.window].includes(x.type))
            ?.sort((a, b) => {
              const nameA = a.name.toUpperCase();
              const nameB = b.name.toUpperCase();
              return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
            })
            .map((o) => (
              <div key={o.name} className="d-flex justify-content-between mb-2">
                <div className={setAddOnOptClassName(o)} title={o.name}>
                  {o.name}{" "}
                  <small className="text-muted">
                    ({resolvePrice(o, o.uom)}{" "}{getUOMDescription(o.uom)})
                  </small>
                </div>
                <div>
                  <ButtonGroup size="sm">
                    <Button
                      color="light"
                      className="m-0 p-1 text-muted"
                      style={{ fontSize: ".75em" }}
                      onClick={() => removeAddOn(o)}
                    >
                      <FontAwesomeIcon icon="minus" />
                    </Button>
                    <div className="text-center px-1 d-flex align-items-center">
                      {selectedAddOns.reduce(
                        (total, x) =>
                          x.id === o.id && x.name === o.name
                            ? total + 1
                            : total,
                        0
                      )}
                    </div>
                    <Button
                      color="light"
                      className="m-0 p-1 text-muted"
                      style={{ fontSize: ".75em" }}
                      onClick={() => addAddOn(o)}
                    >
                      <FontAwesomeIcon icon="plus" />
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            ))}
          {editingContext !== "skuTemplate" ? null : (
            <>
          <div style={{fontWeight: "bold", padding: 0, margin: 0, textDecoration: "underline"}}>Default Only Options:</div>
          {addOnOpts
            .filter((x) => x.isDefault)
            .filter((x) => [addOnTypes.window].includes(x.type))
            ?.sort((a, b) => {
              const nameA = a.name.toUpperCase();
              const nameB = b.name.toUpperCase();
              return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
            })
            .map((o) => (
              <div key={o.name} className="d-flex justify-content-between mb-2">
                <div className={setAddOnOptClassName(o)} title={o.name}>
                  {o.name}{" "}
                  <small className="text-muted">
                    ({resolvePrice(o, o.uom)}{" "}{getUOMDescription(o.uom)})
                  </small>
                </div>
                <div>
                  <ButtonGroup size="sm">
                    <Button
                      color="light"
                      className="m-0 p-1 text-muted"
                      style={{ fontSize: ".75em" }}
                      onClick={() => removeAddOn(o)}
                    >
                      <FontAwesomeIcon icon="minus" />
                    </Button>
                    <div className="text-center px-1 d-flex align-items-center">
                      {selectedAddOns.reduce(
                        (total, x) =>
                          x.id === o.id && x.name === o.name
                            ? total + 1
                            : total,
                        0
                      )}
                    </div>
                    <Button
                      color="light"
                      className="m-0 p-1 text-muted"
                      style={{ fontSize: ".75em" }}
                      onClick={() => addAddOn(o)}
                    >
                      <FontAwesomeIcon icon="plus" />
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            ))}
            </>
          )}
          </div>
        </SidebarCollapse>
        <SidebarCollapse title="Electrical">
          <div>
            {addOnOpts
              .filter((x) => !x.isDefault)
              .filter((x) =>
                [
                  addOnTypes.switch,
                  addOnTypes.outlet,
                  addOnTypes.doubleOutlet,
                  addOnTypes.breakerBox,
                  addOnTypes.light
                ].includes(x.type)
              )
              ?.sort((a, b) => {
                const nameA = a.name.toUpperCase();
                const nameB = b.name.toUpperCase();
                return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
              })
              .map((o) => (
                <div key={o.name} className="d-flex justify-content-between mb-2">
                  <div className={setAddOnOptClassName(o)} title={o.name}>
                    {o.name}{" "}
                    <small className="text-muted">
                      ({resolvePrice(o, o.uom)}{" "}{getUOMDescription(o.uom)})
                    </small>
                  </div>
                  <div>
                    <ButtonGroup size="sm">
                      <Button
                        color="light"
                        className="m-0 p-1 text-muted"
                        style={{ fontSize: ".75em" }}
                        onClick={() => removeAddOn(o)}
                      >
                        <FontAwesomeIcon icon="minus" />
                      </Button>
                      <div className="text-center px-1 d-flex align-items-center">
                        {selectedAddOns.reduce(
                          (total, x) =>
                            x.id === o.id && x.name === o.name
                              ? total + 1
                              : total,
                          0
                        )}
                      </div>
                      <Button
                        color="light"
                        className="m-0 p-1 text-muted"
                        style={{ fontSize: ".75em" }}
                        onClick={() => addAddOn(o)}
                      >
                        <FontAwesomeIcon icon="plus" />
                      </Button>
                    </ButtonGroup>
                  </div>
                </div>
            ))}
          {editingContext !== "skuTemplate" ? null : (
            <>
            <div style={{fontWeight: "bold", padding: 0, margin: 0, textDecoration: "underline"}}>Default Only Options:</div>
            {addOnOpts
              .filter((x) => x.isDefault)
              .filter((x) =>
                [
                  addOnTypes.switch,
                  addOnTypes.outlet,
                  addOnTypes.doubleOutlet,
                  addOnTypes.breakerBox,
                  addOnTypes.light
                ].includes(x.type)
              )
              ?.sort((a, b) => {
                const nameA = a.name.toUpperCase();
                const nameB = b.name.toUpperCase();
                return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
              })
              .map((o) => (
                <div key={o.name} className="d-flex justify-content-between mb-2">
                  <div className={setAddOnOptClassName(o)} title={o.name}>
                    {o.name}{" "}
                    <small className="text-muted">
                      ({resolvePrice(o, o.uom)}{" "}{getUOMDescription(o.uom)})
                    </small>
                  </div>
                  <div>
                    <ButtonGroup size="sm">
                      <Button
                        color="light"
                        className="m-0 p-1 text-muted"
                        style={{ fontSize: ".75em" }}
                        onClick={() => removeAddOn(o)}
                      >
                        <FontAwesomeIcon icon="minus" />
                      </Button>
                      <div className="text-center px-1 d-flex align-items-center">
                        {selectedAddOns.reduce(
                          (total, x) =>
                            x.id === o.id && x.name === o.name
                              ? total + 1
                              : total,
                          0
                        )}
                      </div>
                      <Button
                        color="light"
                        className="m-0 p-1 text-muted"
                        style={{ fontSize: ".75em" }}
                        onClick={() => addAddOn(o)}
                      >
                        <FontAwesomeIcon icon="plus" />
                      </Button>
                    </ButtonGroup>
                  </div>
                </div>
            ))}
            </>
          )}
          </div>
        </SidebarCollapse>
        <SidebarCollapse title="Other">
          <div>
          {addOnOpts
            .filter((x) => !x.isDefault)
            .filter((x) =>
              [
                addOnTypes.other,
                addOnTypes.loft,
                addOnTypes.singleWorkbench,
                addOnTypes.doubleWorkbench,
                addOnTypes.shelving,
              ].includes(x.type)
            )
            ?.sort((a, b) => {
              const nameA = a.name ? a.name.toUpperCase() : "";
              const nameB = b.name ? b.name.toUpperCase() : "";
              return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
            })
            .map((o) => (
              <div key={o.name} className="d-flex justify-content-between mb-2">
                <div className={setAddOnOptClassName(o)} title={o.name}>
                  {o.name}{" "}
                  <small className="text-muted">
                    ({resolvePrice(o, o.uom)} {getUOMDescription(o.uom)})
                  </small>
                </div>
                <div>
                  <ButtonGroup size="sm">
                    <Button
                      color="light"
                      className="m-0 p-1 text-muted"
                      style={{ fontSize: ".75em" }}
                      onClick={() => removeAddOn(o)}
                    >
                      <FontAwesomeIcon icon="minus" />
                    </Button>
                    <div className="text-center px-1 d-flex align-items-center">
                      {selectedAddOns.reduce(
                        (total, x) =>
                          x.id === o.id && x.name === o.name
                            ? total + 1
                            : total,
                        0
                      )}
                    </div>
                    <Button
                      color="light"
                      className="m-0 p-1 text-muted"
                      style={{ fontSize: ".75em" }}
                      onClick={() => addAddOn(o)}
                    >
                      <FontAwesomeIcon icon="plus" />
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            ))}
          {editingContext !== "skuTemplate" ? null : (
            <>
          <div style={{fontWeight: "bold", padding: 0, margin: 0, textDecoration: "underline"}}>Default Only Options:</div>
          {addOnOpts
            .filter((x) => x.isDefault)
            .filter((x) =>
              [
                addOnTypes.other,
                addOnTypes.loft,
                addOnTypes.singleWorkbench,
                addOnTypes.doubleWorkbench,
                addOnTypes.shelving,
              ].includes(x.type)
            )
            ?.sort((a, b) => {
              const nameA = a.name ? a.name.toUpperCase() : "";
              const nameB = b.name ? b.name.toUpperCase() : "";
              return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
            })
            .map((o) => (
              <div key={o.name} className="d-flex justify-content-between mb-2">
                <div className={setAddOnOptClassName(o)} title={o.name}>
                  {o.name}{" "}
                  <small className="text-muted">
                    ({resolvePrice(o, o.uom)} {getUOMDescription(o.uom)})
                  </small>
                </div>
                <div>
                  <ButtonGroup size="sm">
                    <Button
                      color="light"
                      className="m-0 p-1 text-muted"
                      style={{ fontSize: ".75em" }}
                      onClick={() => removeAddOn(o)}
                    >
                      <FontAwesomeIcon icon="minus" />
                    </Button>
                    <div className="text-center px-1 d-flex align-items-center">
                      {selectedAddOns.reduce(
                        (total, x) =>
                          x.id === o.id && x.name === o.name
                            ? total + 1
                            : total,
                        0
                      )}
                    </div>
                    <Button
                      color="light"
                      className="m-0 p-1 text-muted"
                      style={{ fontSize: ".75em" }}
                      onClick={() => addAddOn(o)}
                    >
                      <FontAwesomeIcon icon="plus" />
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            ))}
          </>
          )}
          </div>
        </SidebarCollapse>
        <div className="d-flex justify-content-between mt-2 mb-3">
          <div className="text-nowrap overflow-hidden fw-bold">Total</div>
          <div className="fw-bold">
            {ui_helpers.formatCurrencyWholeDollar(resolvedPriceTotal, 0)}
            {standardPriceTotal === resolvedPriceTotal || _.some(selectedAddOns, ao => ao.uom === unitsOfMeasure.percentOfBase)
              ? null
              : (<span className="text-primary" 
                  style={{fontWeight: "bold", fontSize: "1.1rem"}} 
                  title={`The agreed price of ${ui_helpers.formatCurrencyWholeDollar(resolvedPriceTotal, 0)} is different from the standard price of ${ui_helpers.formatCurrencyWholeDollar(standardPriceTotal, 0)}`}>*</span>)
            }
          </div>
        </div>
        <InputGroup className="my-3">
          <Input
            type="text"
            value={noteText}
            maxLength={200}
            onChange={(e) => setNoteText(e.target.value)}
            placeholder="Add Text Note..."
          />
          <Button
            color="primary"
            onClick={() => {
              addAddOn({
                addOnId: -1,
                name: noteText,
                type: addOnTypes.textNote,
                price: noteTextPrice ? parseFloat(noteTextPrice) : 0,
                rotation: 0,
              });
              setNoteText("");
              setNoteTextPrice("");
            }}
          >
            <FontAwesomeIcon icon="plus" />
          </Button>
        </InputGroup>
        <InputGroup className="my-3">
          <InputGroupText>$</InputGroupText>
          <Input
            type="text"
            value={noteTextPrice}
            onChange={(e) => setNoteTextPrice(e.target.value)}
            placeholder="Text Note Optional Price..."
          />
        </InputGroup>
        <SidebarCollapse title="Selected Add-Ons" defaultOpen>
          {selectedAddOns.length > 0 ? (
            <>
              <div className="d-grid mt-3 mb-1">
                <Button size="sm" color="dark" onClick={actions.showAll}>
                  Show All
                </Button>
              </div>
              <div className="d-grid mb-5">
                <Button size="sm" color="dark" onClick={actions.hideAll}>
                  Hide All
                </Button>
              </div>
            </>
          ) : (
            "No Add-Ons Selected."
          )}
          {selectedAddOns.map((a) => (
            <div key={a.domId} className="d-flex justify-content-between mb-2">
              <div
                className={
                  controlTarget === a.domId ? "fw-bold text-primary" : null
                }
                style={{
                  cursor: controlTarget === a.domId ? "auto" : "pointer",
                }}
                onClick={() => setControlTarget(a.domId)}
              >
                {a.name}
                <span style={{display: "block", color: "gray"}}>
                  {a.isDefault ? "(Default Add-On)" : null}
                </span>
              </div>
              <ButtonGroup size="sm">
                <Button
                  color="dark"
                  onClick={() =>
                    actions.updateAddOn({ ...a, hide: a.hide ? false : true })
                  }
                >
                  <FontAwesomeIcon icon={a.hide ? "eye" : "eye-slash"} />
                </Button>
                <Button color="danger" className="text-white" onClick={() => actions.removeAddOn(a)}>
                  <FontAwesomeIcon icon="trash" />
                </Button>
              </ButtonGroup>
            </div>
          ))}
        </SidebarCollapse>
      </div>
      
      {/* <div className={`${styles.alertBox} bg-light shadow `} disabled={!tipVisible}>
          <Alert color="info" className={styles.infoAlert} toggle={()=>setTipVisible(!tipVisible)}  disabled={!tipVisible}>
            <h5>
              <FontAwesomeIcon icon="info-circle" className="me-2" />
              Tip
            </h5>
            {tipVisible ? <p>{info.message}</p> : null}
            
          </Alert>
      </div>
 */}
      {/* SAVE PREVIEW */}
      <svg
        id="savePreview"
        viewBox={`${-padding.left} ${-padding.top} ${
          exterior.width + padding.left + padding.right
        } ${exterior.height + padding.top + padding.bottom} `}
        style={{ backgroundColor: "#fff", display: "none" }}
      >
        {displaySettings.showGrid ? <Grid id="previewGrid" /> : null}
        {displaySettings.showRulers ? (
          <Rulers displaySettings={displaySettings} exterior={exterior} />
        ) : null}
        <text
          textAnchor="middle"
          className={styles.disclaimerTxt}
          fontSize={displaySettings.fontSize}
          x={exterior.width / 2}
          y={0 - displaySettings.rulerOffset - displaySettings.fontSize * 2}
        >
          This drawing may not be exact, it is designed to show a general layout
          and placement of options.
        </text>
        <Exterior />
        {selectedAddOns.map((a) =>
          a.hide ? null : <AddOn key={a.domId} {...a} />
        )}
        <rect
          x={0}
          y={0}
          height={exterior.height}
          width={exterior.width}
          fill="rgba(0,0,0,0)"
        />
      </svg>
    </>
  );
}

export default PrimarySidebar;
